import {
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonLabel,
	IonSpinner
} from '@ionic/react';
import './ListGuide.css';
import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import 'moment/locale/it';
import { requestWebService, LIST_ALLIEVI_INSTRUCTOR } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';

const ListAllieviInstructor: React.FC = () => {
	let { } = useParams<any>();

	const [list, setList] = React.useState<Array<any>>([]);
	const [loading, setLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		getInfoStudentCalendario();
	}, []);

	const getInfoStudentCalendario = async () => {
		requestWebService(LIST_ALLIEVI_INSTRUCTOR, {}).then((res) => {
			if (res && res.status == 200) {
				setList(res.list || []);
				setLoading(false);
			} else {
				window.history.back();
				return false;
			}
		})
	}

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/calendario" />
					</IonButtons>
					<IonTitle>Allievi</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{loading && list.length == 0 && <SpinnerLoading />}
				{!loading && list && list.map((item, index) => {
					return <IonItem key={index}>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{item.nome + " " + item.cognome}</h3>
							</IonText>
							{item.email && <p>Email : {item.email}</p>}
							{item.cellulare && <p>Cellulare : {item.cellulare}</p>}
							{item.telefono && <p>Telefono : {item.telefono}</p>}
						</IonLabel>
					</IonItem>
				})}
			</IonContent>
		</IonPage>
	);
};

export default ListAllieviInstructor;
