import {
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonLabel,
	IonSpinner
} from '@ionic/react';
import './ListGuide.css';
import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import 'moment/locale/it';
import { requestWebService, INFO_STUDENT_CALENDARIO } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';

const ListGuidePage: React.FC = () => {
	let { corso, iscrizione } = useParams<any>();

	const [calendar, setCalendar] = React.useState<Array<any>>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	React.useEffect(() => {
		if (corso && iscrizione) {
			getInfoStudentCalendario();
		}
	}, []);

	const getInfoStudentCalendario = async () => {
		requestWebService(INFO_STUDENT_CALENDARIO, { id_iscrizione: iscrizione }).then((res) => {
			if (res && res.status == 200) {
				if (!res.instructor) {
					window.history.back();
					return false;
				}
				var _calendar: any = new Array();
				if (res.calendar && res.calendar.length > 0) {
					_calendar = res.calendar.sort((a: any, b: any) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0))
				}
				setLoading(false);
				setCalendar(_calendar);
			} else {
				window.history.back();
				return false;
			}
		})
	}

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/calendario" />
					</IonButtons>
					<IonTitle>Guide fatte/da fare</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{loading && calendar.length == 0 && <SpinnerLoading />}
				{!loading && calendar && calendar.map((item, index) => {
					return <IonItem key={index}>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{moment.unix(item.data_inizio).format("HH:mm")} - {moment.unix(item.data_inizio).format("HH:mm")}</h3>
							</IonText>
							<p>{moment.unix(item.data).format("DD MMM YYYY")}</p>
						</IonLabel>
					</IonItem>
				})}
			</IonContent>
		</IonPage>
	);
};

export default ListGuidePage;
