import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonMenuToggle,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar
} from '@ionic/react';
import './Calendario.css';
import { menu } from 'ionicons/icons';
import { requestWebService, INFO_USER_CALENDARIO } from '../utils/webservice';
import { useQuery } from 'react-query';
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { IonLabel } from '@ionic/react';
import { Link } from 'react-router-dom';
import ExploreContainer from '../components/ExploreContainer';

const Calendario: React.FC = () => {
	const { infoUser } = useContext(AuthContext);

	const { status, data, error } = useQuery<String, any, any>(
		'INFO_USER_CALENDARIO',
		async (): Promise<any> => {
			var res = await requestWebService(INFO_USER_CALENDARIO, { id: infoUser.user });

			if (res && res.status == 200) {
				return {
					courses: res.courses,
					calendar: res.calendar,
					coinStudent: res.coinStudent,
					coinDaPagare: res.coinDaPagare
				};
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);

	const renderPage = () => {
		if (status === 'loading') {
			return <p>Loading</p>;
		}
		if (status === 'error') {
			return <ExploreContainer msg={"Error Message : " + error.message} />;
		}


		if (status === 'success') {
			return (
				<React.Fragment>
					<h3 style={{ padding: 10, fontSize: 15 }}>** 1 COIN CORRISPONDE A 30 MINUTI DI GUIDA </h3>
					{data.courses.length == 0 && <ExploreContainer msg="Calendario non disponbile." />}
					{data.courses.map((item: any, index: number) => {
						var totale_coin = data.coinStudent.filter(
							(e: any) => e.id_iscrizione == item.id_iscrizione
						);
						var coin_da_pagare = data.coinDaPagare.filter(
							(e: any) => e.id_iscrizione == item.id_iscrizione
						);
						var calendar = data.calendar.filter((e: any) => e.id_iscrizione == item.id_iscrizione);
						var coin_disponibili = 0;
						if (totale_coin.length > 0) {
							coin_disponibili = totale_coin[0].totale_coin - calendar.length;
						}

						return (
							<div key={index}>
								<IonCard>
									<IonCardHeader>
										<IonCardSubtitle>Corso</IonCardSubtitle>
										<IonCardTitle style={{ fontSize: 20 }}>{item.corso}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent style={{ paddingLeft: 0, paddingRight: 20 }}>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.fullname_instructor || '-'}</h3>
												</IonText>
												<p>Istruttore</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>
														{totale_coin.length > 0 ? totale_coin[0].totale_coin : 0}
													</h3>
												</IonText>
												<p>Totale coin</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{coin_disponibili}</h3>
												</IonText>
												<p>Coin disponibili</p>
											</IonLabel>
										</IonItem>

										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>
														{coin_da_pagare.length > 0 ? (
															coin_da_pagare[0].coinDaPagare
														) : (
															0
														)}
													</h3>
												</IonText>
												<p>Coin da pagare</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<Link
												to={{
													pathname: '/calendario-corso/' + item.id_corso + '/' + item.id_iscrizione,
													state: {}
												}}
											>
												Visualizza calendario
												</Link>
										</IonItem>
										<IonItem>
											<Link
												to={{
													pathname: '/list-guide-corso/' + item.id_corso + '/' + item.id_iscrizione,
													state: {}
												}}
											>
												Visualizza guide
											</Link>
										</IonItem>
									</IonCardContent>
								</IonCard>
							</div>
						);
					})}
				</React.Fragment>
			);

		}
		return <React.Fragment />;
	};

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuToggle>
							<IonButton>
								<IonIcon icon={menu} color="icon-menu" />
							</IonButton>
						</IonMenuToggle>
					</IonButtons>
					<IonTitle>Calendario</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>{renderPage()}</IonContent>
		</IonPage>
	);
};

export default Calendario;
