import React, { useContext } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuToggle,
	IonButton,
	IonIcon,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import './Corsi.css';
import moment from 'moment';
import { useQuery } from 'react-query';
import { requestWebService, INFO_USER_CORSI } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';
import { isCalcNumber } from '../utils/utils';
import { Link } from 'react-router-dom';

const Corsi: React.FC = () => {
	const { infoUser } = useContext(AuthContext);

	const { status, data, error } = useQuery<String, any, any>(
		'INFO_USER_CORSI',
		async (): Promise<any> => {
			var res = await requestWebService(INFO_USER_CORSI, { id: infoUser.user });
			if (res && res.status == 200) {
				return {
					courses: res.courses,
					payments: res.payments
				};
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);

	const renderPage = () => {
		if (status === 'loading') {
			return <p>Loading</p>;
		}
		if (status === 'error') {
			return <p> Error Message : {error.message}</p>;
		}

		if (status === 'success') {
			return (
				<React.Fragment>
					{data.courses.map((item: any, index: number) => {
						var payments = data.payments.filter((e: any) => e.id_iscrizione == item.id_iscrizione);
						return (
							<div key={index}>
								<IonCard>
									<IonCardHeader>
										<IonCardSubtitle>Corso</IonCardSubtitle>
										<IonCardTitle style={{ fontSize: 20 }}>{item.corso}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent style={{ paddingLeft: 0, paddingRight: 20 }}>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.fullname_instructor || '-'}</h3>
												</IonText>
												<p>Istruttore</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.importo_iscrizione.toFixed(2)} €</h3>
												</IonText>
												<p>Importo</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>
														{(isCalcNumber(item.importo_iscrizione) -
															isCalcNumber(item.sumPayments)).toFixed(2) + ' €'}
													</h3>
												</IonText>
												<p>Da pagare</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{moment(item.data_iscrizione).format('DD MMM YYYY')}</h3>
												</IonText>
												<p>Data iscrizione</p>
											</IonLabel>
										</IonItem>
										{payments.length > 0 && (
											<IonItem>
												<Link
													to={{
														pathname: '/payments-corso/' + item.id_corso,
														state: { payments: payments, corso: item.corso }
													}}
												>
													Visualizza pagamenti
													</Link>
											</IonItem>
										)}
									</IonCardContent>
								</IonCard>
							</div>
						);
					})}
				</React.Fragment>
			);
		}
		return <React.Fragment />;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuToggle>
							<IonButton>
								<IonIcon icon={menu} color="icon-menu" />
							</IonButton>
						</IonMenuToggle>
					</IonButtons>
					<IonTitle>Corsi</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>{renderPage()}</IonContent>
		</IonPage>
	);
};

export default Corsi;
