import { IonList, IonRadioGroup, IonLabel, IonItem, IonRadio, IonSpinner, IonAlert, } from '@ionic/react';
import React from 'react';
import './ModalOrari.css';
import moment from 'moment';
import { requestWebService, GET_ORARI_GUIDE_DAY, UPDATE_ORARIO_STUDENT_CALENDARIO, ADD_ORARIO_STUDENT_CALENDARIO } from '../utils/webservice';

interface ContainerProps {
  istruttore: number;
  onChange: Function;
  isOpen: Boolean;
  day?: String;
  idIscrizione: number;
  iduser: number;
  id: number;
}

interface OrariProps {
  inizio: string;
  fine: string;
  show: boolean;
}

function compare(a: any, b: any) {
  if (a.inizio < b.inizio) {
    return -1;
  }
  if (a.inizio > b.inizio) {
    return 1;
  }
  return 0;
}


const ModalOrari: React.FC<ContainerProps> = ({ isOpen, istruttore, onChange, day, idIscrizione, iduser, id }) => {

  const [selected, setSelected] = React.useState<string>('');
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(false);
  const [orari, setOrari] = React.useState<any>([]);


  React.useEffect(() => {
    async function init() {
      let _array: OrariProps[] = [];


      requestWebService(GET_ORARI_GUIDE_DAY, { data: moment(day + "T00:00:00").unix(), inst: istruttore }).then((res) => {
   
        if (res && res.status == 200 && res.orari.length > 0) {
          var date = moment(day + "T00:00:00").format("YYYY-MM-DD");

          res.orari.forEach((item: any) => {
            var _conf = moment(day + "T00:00:00").format("YYYY-MM-DD");
            var oggi = moment(day + "T00:00:00").format("YYYY-MM-DD");
            var orainizio = moment(date + "T" + item.ora_inizio);
            var orafine = moment(date + "T" + item.ora_fine);
            var mm = orafine.diff(orainizio, 'minute') / 30;
            var _conf_date = moment(day + "T00:00:00");
            for (var i = 0; i < mm; i++) {
              var _show = true;
              var _d = moment(_conf_date).format('YYYY-MM-DD');
              var _o_i = moment(_d + "T" + moment(orainizio).add(30 * i, 'm').format("HH:mm")).unix();
              if ((oggi == _conf && moment(day + "T00:00:00").format("HH:mm") > moment(orainizio).add(30 * i, 'm').format("HH:mm"))) {
                _show = false
              }
              if (moment().unix() > _o_i) {
                _show = false
              }
              _array.push({
                inizio: moment(orainizio).add(30 * i, 'm').format("HH:mm"),
                fine: moment(orainizio).add(30 * (i + 1), 'm').format("HH:mm"),
                show: _show
              });
            }
          });
          _array = _array.sort(compare);
          res.users.forEach((item: any) => {
            var ora_inizio = "";
            var ora_inizio = moment.unix(item.data_inizio).format("HH:mm");
            var _index = _array.findIndex(e => e.inizio == ora_inizio);
            if (_index >= 0) {
              _array[_index].show = false;
            }
          });
          setOrari(_array);
        }
        setShowLoading(false);
      })
    }
    if (isOpen) {
      init();
    }

    return () => {
      setSelected('');
    }

  }, [isOpen]);


  const selectOrario = (value: string) => {

    if (value) {
      setSelected(value);
      setShowConfirmModal(true);

    }

  }

  const saveNewOrario = () => {
    //var date = moment().format("YYYY-MM-DD")
    var datainizio = moment(day + "T" + selected.split("-")[0] + ":00").format("YYYY-MM-DD HH:mm:ss");
    var datafine = moment(day + "T" + selected.split("-")[1] + ":00").format("YYYY-MM-DD HH:mm:ss");
    var val = {
      id: id || null,
      iduser: iduser,
      id_iscrizione: idIscrizione || null,
      data: moment(day + "").unix(),
      data_inizio: moment(datainizio).unix(),
      data_fine: moment(datafine).unix(),
      inst: istruttore
    };

  

    if (datainizio && datafine && id) {
      requestWebService(UPDATE_ORARIO_STUDENT_CALENDARIO, val).then((res) => {
        if (res && res.status == 200) {
          onChange();
        }
      })
    } else if (datainizio && datafine) {  
      requestWebService(ADD_ORARIO_STUDENT_CALENDARIO, val).then((res) => {
        if (res && res.status == 200) {
          onChange();
        }
      })
    }
  }

  return (
    <div className="container-modal">
      {showLoading && <IonSpinner />}
      {!showLoading && <IonList>
        <IonRadioGroup value={selected} onIonChange={e => {
          selectOrario(e.detail.value)
        }}>
          {orari.map((item: OrariProps, index: number) => {
            return <IonItem key={index}>
              <IonLabel>{item.inizio + " - " + item.fine}</IonLabel>
              <IonRadio disabled={!item.show} slot="end" value={item.inizio + "-" + item.fine} />
            </IonItem>
          })}
        </IonRadioGroup>
      </IonList>}

      <IonAlert
        isOpen={showConfirmModal}
        backdropDismiss={false}
        cssClass="my-custom-class"
        header={'Orario guida'}
        message={'Sei sicuro di voler salvare l\'orario?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              setSelected('');
              setShowConfirmModal(false);

            }
          },
          {
            text: 'SI',
            handler: () => {
              setShowConfirmModal(false);
              saveNewOrario();
            }
          }
        ]}
      />
    </div>
  );
};

export default ModalOrari;
