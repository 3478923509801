import React from 'react';
import {
	IonContent,
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonBackButton,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';
import './PagamentoCorso.css';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const PagamentoCorsoPage: React.FC = () => {
	const { state } = useLocation<any>();

	const [data, setData] = React.useState({
		payments: [],
		corso: ''
	});

	React.useEffect(() => {
		function init() {
			if (!state) {
				window.location.href = '/corsi';
			} else {
				setData({
					payments: state.payments,
					corso: state.corso
				});
			}
		}
		init();
	}, []);

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>{'Pagamenti'}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<h1 style={{ padding: 10 }}>{data.corso}</h1>
				{data.payments &&
					data.payments.map((item: any, index: number) => {
						return (
							<div key={index}>
								<IonCard>
									<IonCardHeader>
										<IonCardSubtitle>Pagamento</IonCardSubtitle>
										<IonCardTitle style={{ fontSize: 20 }}>{item.corso}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent style={{ paddingLeft: 0, paddingRight: 20 }}>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.importo.toFixed(2)} €</h3>
												</IonText>
												<p>Importo</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{moment(item.data).format('DD MMM YYYY')}</h3>
												</IonText>
												<p>Data</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.method}</h3>
												</IonText>
												<p>Tipo di pagamento</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel className="ion-text-wrap">
												<IonText color="dark">
													<h3>{item.note}</h3>
												</IonText>
												<p>note</p>
											</IonLabel>
										</IonItem>
									</IonCardContent>
								</IonCard>
							</div>
						);
					})}
			</IonContent>
		</IonPage>
	);
};

export default PagamentoCorsoPage;
