import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonModal,
	IonLabel,
	IonIcon,
	useIonToast,
	IonAlert,
	IonLoading,
	useIonAlert
} from '@ionic/react';
import './CalendarioCorsoTeorico.css';
import { Calendar, Select, Col, Row } from 'antd';
import React from 'react';
import moment from 'moment';

import 'moment/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import { createOutline, trashOutline } from 'ionicons/icons';
import { requestWebService, SAVE_POSTO_AULA, ORARI_CORSO_TEORICO, ANNULLA_POSTO_AULA } from '../utils/webservice';
import ModalOrari from '../components/ModalOrari';
import { AuthContext } from '../context/AuthContext';
import { useQuery } from 'react-query';

const CalendarioCorsoTeoricoPage: React.FC = () => {
	const { infoUser } = React.useContext(AuthContext);

	const [present] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [calendar, setCalendar] = React.useState<Array<[]>>([]);
	const [calendarUser, setCalendarUser] = React.useState<Array<[]>>([]);
	const [showLoading, setShowLoading] = React.useState(false);
	const [items, setItems] = React.useState<Array<[]>>([]);
	const [selectedDate, setSelectedDate] = React.useState(moment());

	React.useEffect(() => {
		getInfoStudentCalendario(false, moment().format("YYYY-MM-DD"));
	}, []);
	const getInfoStudentCalendario = async (loading: boolean, data: string) => {
		requestWebService(ORARI_CORSO_TEORICO, { data: data }).then((res) => {
			if (res && res.status == 200) {

				const checkData = res.orari.filter(
					(e: any) => moment(e.data).format('YYYY-MM-DD') == moment(selectedDate).format('YYYY-MM-DD')
				);

				setItems(checkData);
				setCalendar(res.orari);
				setCalendarUser(res.calendario);
			} else {
				window.history.back();
				return false;
			}
			if (loading) {
				setShowLoading(false);
			}
		})
	}

	const disabledDate = (current: any) => {
		if (moment(current).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
			return true;
		}
		return false;
	};

	const dateCellRender = (value: any) => {
		const checkData = calendar.filter(
			(e: any) => moment(e.data).format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD')
		);
		// allievi: 0
		// data: "2021-05-21"
		// id: 3
		// ora_fine: "23:30"
		// ora_inizio: "20:30"
		// posti: 5


		if (checkData && checkData.length > 0) {
			return (
				<div style={{ position: 'absolute', top: -3, left: -3, width: 10, height: 10, zIndex: 9 }}>
					<div style={{ width: 10, height: 10, borderRadius: '50%', background: '#eab300' }} />
				</div>
			);
		}

		return <React.Fragment />;
	};

	const onSelect = (value: any) => {
		const checkData = calendar.filter(
			(e: any) => moment(e.data).format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD')
		);
		setSelectedDate(value);
		setItems(checkData);
	};

	const PrenotaAula = (id: number) => {

		presentAlert({
			cssClass: '',
			header: 'Prenota posto in aula',
			message: 'Sei sicuro di voler prenotare?',
			buttons: [
				'Chiudi',
				{
					text: 'SI', handler: (d) => {
						savePostoAula(id);
					}
				},
			],
		})
	}


	const savePostoAula = (id: number) => {
		setShowLoading(true);
		requestWebService(SAVE_POSTO_AULA, { user: infoUser.user, posto_aula: id }).then((res) => {
			if (res && res.status == 200) {
				getInfoStudentCalendario(true, moment().format("YYYY-MM-DD"));
				setTimeout(() => {
					present({
						buttons: [],
						message: "Dati salvati con successo.",
						position: 'bottom',
						duration: 4500
					});
				}, 500)
			} else {
				setShowLoading(false);
				present({
					buttons: [],
					message: "Errore: impossibile salvare i dati.",
					position: 'bottom',
					duration: 4500
				});
			}
		})
		//	SAVE_POSTO_AULA
	}

	const AnnullaPrenotazione = (id: number) => {
		presentAlert({
			cssClass: '',
			header: 'Annulla',
			message: 'Vuoi annullare la prenotazione?',
			buttons: [
				'Chiudi',
				{
					text: 'SI', handler: (d) => {
						annulaPostoAula(id);
					}
				},
			],
		})
	}
	const annulaPostoAula = (id: number) => {
		setShowLoading(true);
		console.log(ANNULLA_POSTO_AULA, { user: infoUser.user, posto_aula: id })
		requestWebService(ANNULLA_POSTO_AULA, { user: infoUser.user, posto_aula: id }).then((res) => {
			console.log(res);
			if (res && res.status == 200) {
				getInfoStudentCalendario(true, moment().format("YYYY-MM-DD"));
				setTimeout(() => {
					present({
						buttons: [],
						message: "Dati salvati con successo.",
						position: 'bottom',
						duration: 4500
					});
				}, 500)
			} else {
				setShowLoading(false);
				present({
					buttons: [],
					message: "Errore: impossibile salvare i dati.",
					position: 'bottom',
					duration: 4500
				});
			}
		})
		//	SAVE_POSTO_AULA
	}


	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/bacheca" />
					</IonButtons>
					<IonTitle>Calendario Aula</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="site-calendar-demo-card">
					<Calendar
						value={selectedDate}
						headerRender={({ value, type, onChange, onTypeChange }) => {
							const start = 0;
							const end = 12;
							const monthOptions = [];

							const current = value.clone();
							const localeData = value.localeData();
							const months = [];
							for (let i = 0; i < 12; i++) {
								current.month(i);
								months.push(localeData.monthsShort(current));
							}

							for (let index = start; index < end; index++) {
								monthOptions.push(
									<Select.Option className="month-item" value={months[index]} key={`${index}`}>
										{months[index]}
									</Select.Option>
								);
							}
							const month = value.month();

							const year = value.year();
							const year_ = parseInt(moment().format("YYYY"));
							const options = [];
							for (let i = year_; i < year_ + 2; i += 1) {
								options.push(
									<Select.Option key={i} value={i} className="year-item">
										{i}
									</Select.Option>
								);
							}
							const mesi = [
								{ titolo: 'gen', value: 1 },
								{ titolo: 'feb', value: 2 },
								{ titolo: 'mar', value: 3 },
								{ titolo: 'apr', value: 4 },
								{ titolo: 'mag', value: 5 },
								{ titolo: 'giu', value: 6 },
								{ titolo: 'lug', value: 7 },
								{ titolo: 'ago', value: 8 },
								{ titolo: 'set', value: 9 },
								{ titolo: 'ott', value: 10 },
								{ titolo: 'nov', value: 11 },
								{ titolo: 'dic', value: 12 }
							];
							return (
								<div style={{ padding: 8 }}>
									<Row gutter={8}>
										<Col>
											<Select
												size="small"
												dropdownMatchSelectWidth={false}
												className="my-year-select"
												onChange={(newYear) => {
													const now = value.clone().year(parseInt(newYear));
													onChange(now);
												}}
												value={String(year)}
											>
												{options}
											</Select>
										</Col>
										<Col>
											<Select
												size="small"
												dropdownMatchSelectWidth={false}
												value={moment(value).format('MMM')}
												onChange={(selectedMonth) => {
													var filter = mesi.filter((e: any) => e.titolo == selectedMonth);
													const newValue = value.clone();
													newValue.month(parseInt((filter[0].value - 1).toString(), 10));
													onChange(newValue);
												}}
											>
												{monthOptions}
											</Select>
										</Col>
									</Row>
								</div>
							);
						}}
						dateCellRender={dateCellRender}
						locale={locale}
						mode={'month'}
						disabledDate={disabledDate}
						fullscreen={false}
						onSelect={onSelect}
						style={{ width: window.innerWidth }}
					/>
				</div>
				{items && items.map((item: any, index: number) => {
					var prenotato = false;
					if (calendarUser.length > 0) {
						const checkUser = calendarUser.findIndex((e: any) => e.id_orari_corso_teorico == item.id);
						if (checkUser >= 0) {
							prenotato = true;
						}
					}

					return (
						<IonItem key={index}>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{moment(item.data).format("DD MMM YYYY") + " " + item.ora_inizio}</h3>
								</IonText>
								<p>Posti disponibili: {item.posti - item.allievi}</p>

							</IonLabel>
							{((item.posti > item.allievi) && !prenotato) && <IonButton fill="outline" slot="end" color="crocetta" onClick={() => { PrenotaAula(item.id) }}>Prenota</IonButton>}
							{(prenotato) && <IonButton fill="outline" slot="end" color="medium" onClick={() => { AnnullaPrenotazione(item.id) }}>Prenotato</IonButton>}
						</IonItem>
					);
				})}
			</IonContent>
			<IonLoading
				cssClass="my-custom-class"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={''}
			/>
		</IonPage>
	);
};

export default CalendarioCorsoTeoricoPage;
