import {
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonLabel,
	IonButton,
	IonSelect,
	IonSelectOption,
	IonAlert,
	IonLoading,
	useIonToast
} from '@ionic/react';
import QrReader from 'react-qr-reader';
import './QrcodeInstructor.css';
import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import 'moment/locale/it';
import { requestWebService, ADD_ORARIO_STUDENT_CALENDARIO_INSTRUCTOR, CHECK_QRCODE_STUDENT, LINK_ADD_COIN_REGALO } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';
import { compare } from '../utils/utils';

const QrcodeInstructor: React.FC = () => {
	let { } = useParams<any>();
	const { infoUser } = React.useContext(AuthContext);
	const [present] = useIonToast();
	const [showLoading, setShowLoading] = React.useState(false);

	const [state, setState] = React.useState({
		spinning: false,
		calendar_student: [],
		coin: 0,
		student: '',
		qrcode: '',
		errorMsg: '',
		successMsg: '',
		orari: [],
		showAddCoin: false,
		courses_student: [],
		orario_saved: '',
		showConfirmModal: false
	});

//26-21-qr-code


	React.useEffect(() => {

		if (state.qrcode != '') {
			if (state.qrcode.split('-').length == 4) {
				checkQrCode();
			} else {
				setState({
					...state,
					student: '',
					orari: [],
					coin: 0,
					successMsg: '',
					errorMsg: "",
					spinning: false,
					showConfirmModal: false
				})
			}
		}
		return () => { }
	}, [state.qrcode]);

	const checkQrCode = async () => {
		var qr = state.qrcode.split('-');

		var res = await requestWebService(CHECK_QRCODE_STUDENT, { user: qr[0], iscrizione: qr[1] });

		var errorMsg = '';
		var courses_student: any = [];
		var student: any = '';
		var _orari: any = [];
		var coin = 0;
		var array: any = [];
		var calendar_student: any = [];
		var qrcode = state.qrcode;

		if (res && res.courses_student && res.courses_student.length > 0) {
			courses_student = res.courses_student;
		}

		if (res && res.status == 200) {
			var allievo: any = res.user;
			student = allievo.nome + " " + allievo.cognome;
			res.orari.forEach((item: any) => {
				var _conf = moment().format("YYYY-MM-DD");
				var oggi = moment().format("YYYY-MM-DD");
				var orainizio = moment(oggi + "T" + item.ora_inizio);
				var orafine = moment(oggi + "T" + item.ora_fine);
				var mm = orafine.diff(orainizio, 'minute') / 30;
				var _conf_date = moment();

				for (var i = 0; i < mm; i++) {
					var _show = true;
					var _d = moment(_conf_date).format('YYYY-MM-DD');
					var _o_i = moment(_d + "T" + moment(orainizio).add(30 * i, 'm').format("HH:mm")).unix();
					if ((oggi == _conf && moment().format("HH:mm") > moment(orainizio).add(30 * i, 'm').format("HH:mm"))) {
						_show = false
					}
					if (moment().unix() > _o_i) {
						_show = false
					}
					array.push({
						inizio: moment(orainizio).add(30 * i, 'm').format("HH:mm"),
						fine: moment(orainizio).add(30 * (i + 1), 'm').format("HH:mm"),
						show: _show
					});
				}

			});
			_orari = array.sort(compare);
	
			res.users.forEach((item: any, index: number) => {

				if (item.iduser != allievo.iduser) {
					var ora_inizio = moment.unix(item.data_inizio).format("HH:mm");				
					var _index = _orari.findIndex((e: any) => e.inizio == ora_inizio);				
					if (_index >= 0) {
						_orari[_index].show = false;
					}
				} else {
					var ora_inizio = moment.unix(item.data_inizio).format("HH:mm");					
					var _index = _orari.findIndex((e: any) => e.inizio == ora_inizio);
					if (_index >= 0) {
						_orari[_index].show = false;
					}
				}
			});
			coin = res.coin;

			calendar_student = res.calendar_student;
		} else if (res && res.status == 201) {
			//error qrcode non valido - instructor
			errorMsg = "Errore: questo allievo ha un altro istruttore.";
		} else if (res && res.status == 203) {
			//error qrcode non valido
			errorMsg = "Errore: qrcode non valido.";
		} else {
			//	qrcode = '';
			errorMsg = "Errore: impossibile raggiungere il server.";
		}

		setState({
			...state,
			qrcode: qrcode,
			courses_student: courses_student,
			showAddCoin: courses_student.length > 0 ? true : false,
			successMsg: '',
			student: student,
			calendar_student: calendar_student,
			orari: _orari,
			coin: coin,
			errorMsg: errorMsg,
			spinning: false
		})
	}

	const confirmSetOrario = (value: string) => {
		setState({
			...state,
			showConfirmModal: true,
			orario_saved: value
		})
	}

	const setOrario = async () => {
		var qr = state.qrcode.split('-');
		var data = moment().format("YYYY-MM-DD");
		var value = state.orario_saved;
		var ora_inizio = value.split("-")[0];
		var ora_fine = value.split("-")[1];
		var val = {
			id: null,
			iduser: qr[0],
			id_iscrizione: qr[1],
			data: moment().unix(),
			data_inizio: moment(data + " " + ora_inizio + ":00").unix(),
			data_fine: moment(data + " " + ora_fine + ":00").unix()
		}

		setShowLoading(true);

		setTimeout(() => {


		}, 4000)


		var res = await requestWebService(ADD_ORARIO_STUDENT_CALENDARIO_INSTRUCTOR, val);
		setShowLoading(false);

		if (res && res.status && res.status == 200) {
			present({
				buttons: [],
				message: 'Dati salvati con successo.',
				position: 'bottom',
				duration: 6500
			});
			setState({
				...state,
				showConfirmModal: false,
				successMsg: 'Dati salvati con succeso.',
				student: '',
				orari: [],
				calendar_student: [],
				coin: 0,
				errorMsg: '',
				spinning: false
			})
		} else {
			present({
				buttons: [],
				message: 'Errore : impossibile salvare i dati.',
				position: 'bottom',
				duration: 6500
			});
			setState({
				...state,
				showConfirmModal: false,
				errorMsg: 'Errore : impossibile salvare i dati.',
			})
		}

	}


	const addCoin = () => {
		setShowLoading(true);
		var user: any = state.courses_student[0];
		var formAdd = {
			id_autoscuola: infoUser.autoscuola,
			iduser: user.iduser,
			id_iscrizione: user.id_iscrizione,
			importo: user.coin,
			note: "",
			coin: 1,
			method_payment: "Da pagare",
			data_ins: moment().format("YYYY-MM-DD HH:mm")
		};

		requestWebService(LINK_ADD_COIN_REGALO, formAdd).then((res) => {
			setShowLoading(false);
			if (res && res.status == 200) {
				checkQrCode();
			} else {
				present({
					buttons: [],
					message: 'Errore : impossibile salvare i dati.',
					position: 'bottom',
					duration: 6500
				});
			}
		})

	}

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/bacheca-instructor" />
					</IonButtons>
					<IonTitle>Scan QrCode</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div style={{ marginTop: 20 }}>
					{!state.qrcode && <QrReader
						facingMode={'environment'}
						delay={300}
						style={{ width: 250, height: 250, background: '#000', margin: '0 auto' }}
						onError={(err) => {

						}}
						onScan={(data) => {
							if (data) {
								setState({
									...state,
									qrcode: data
								})
							}
						}}
					/>}
				</div>
				<div style={{ textAlign: 'center', marginTop: 20 }}>
					{state.qrcode && <h1>QRCode - <span style={{ color: 'green' }}>{state.qrcode}</span></h1>}
					{!state.successMsg && state.student && !state.errorMsg && <h3>{state.student}</h3>}
					{!state.successMsg && state.student && state.coin >= 0 && !state.errorMsg && <h3>Coin disponibili : {state.coin}</h3>}
					{!state.successMsg && state.coin > 0 && state.orari.length > 0 && !state.errorMsg && <h3>Orari disponibili</h3>}
					{!state.successMsg && state.qrcode && state.orari.length <= 0 && !state.errorMsg && <h3 style={{ color: '#ff0000' }}>Nessun orario disponibile</h3>}


					{!state.successMsg && state.coin > 0 && state.orari.length > 0 && !state.errorMsg && <IonItem>
						<IonLabel>Selezione orario</IonLabel>
						<IonSelect cancelText={"Chiudi"} okText={"Salva"} value={state.orario_saved} placeholder="08:00" onIonChange={e => {
							if (e && e.detail && e.detail.value) {
								confirmSetOrario(e.detail.value)
							}
						}}>
							{state.orari.map((item: any, index: number) => {
								return <IonSelectOption key={index} disabled={!item.show} value={item.inizio + "-" + item.fine}>{item.inizio + " - " + item.fine}</IonSelectOption>
							})}
						</IonSelect>
					</IonItem>}


					{state.errorMsg && <h1 style={{ color: 'red' }}>{state.errorMsg}</h1>}
					{state.successMsg && <h1 style={{ color: 'green' }}>{state.successMsg}</h1>}
					{state.showAddCoin && <IonButton style={{ marginTop: 20 }} onClick={() => { addCoin() }}>Aggiungi coin da pagare</IonButton>}
				</div>
				<IonAlert
					isOpen={state.showConfirmModal}
					backdropDismiss={false}
					cssClass="my-custom-class"
					header={'Orari'}
					message={'Sei sicuro di voler salvare i dati ?'}
					buttons={[
						{
							text: 'No',
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {
								setState({
									...state,
									showConfirmModal: false,
									orario_saved: ''
								})
							}
						},
						{
							text: 'SI',
							handler: () => {
								setState({
									...state,
									showConfirmModal: false
								})
								setOrario();
							}
						}
					]}
				/>
				<IonLoading
					cssClass="my-custom-class"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					message={''}
				/>
			</IonContent>

		</IonPage>
	);
};

export default QrcodeInstructor;
