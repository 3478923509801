import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonModal,
	IonLabel,
	IonIcon,
	useIonToast,
	IonAlert,
	IonLoading
} from '@ionic/react';
import './CalendarioCorso.css';
import { Calendar, Select, Col, Row } from 'antd';
import React from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { getUnixDate, activeDaysCalendarStudent } from '../utils/utils';
import 'moment/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import { createOutline, trashOutline } from 'ionicons/icons';
import { requestWebService, REMOVE_ORARIO_GUIDA_CALENDARIO, INFO_STUDENT_CALENDARIO } from '../utils/webservice';
import ModalOrari from '../components/ModalOrari';
import { AuthContext } from '../context/AuthContext';

const CalendarioCorsoPage: React.FC = () => {
	const { infoUser } = React.useContext(AuthContext);
	let { corso, iscrizione } = useParams<any>();

	const [present] = useIonToast();
	const [showModal, setShowModal] = React.useState({
		isOpen: false,
		day: '',
		id: 0
	});
	const [calendar, setCalendar] = React.useState<Array<[]>>([]);
	const [items, setItems] = React.useState<Array<[]>>([]);
	const [coin, setCoin] = React.useState(0);
	const [instructor, setInstructor] = React.useState(0);
	const [btnGuida, setBtnGuida] = React.useState(0);
	const [selectedDate, setSelectedDate] = React.useState(moment());
	const [showConfirmModal, setShowConfirmModal] = React.useState({
		show: false,
		id: 0
	});
	const [showLoading, setShowLoading] = React.useState(false);

	React.useEffect(() => {
		if (corso && iscrizione) {
			getInfoStudentCalendario('');
		}
	}, []);


	const getInfoStudentCalendario = async (loading: string) => {
		requestWebService(INFO_STUDENT_CALENDARIO, { id_iscrizione: iscrizione }).then((res) => {
			if (res && res.status == 200) {
				if (!res.instructor) {
					window.history.back();
					return false;
				}
			
				const checkData = res.calendar.filter(
					(e: any) => moment.unix(e.data).format('YYYY-MM-DD') == moment(selectedDate).format('YYYY-MM-DD')
				);
				setCalendar(res.calendar);
				setItems(checkData);
				setInstructor(res.instructor);
				setBtnGuida(res.btn_guida);
				setCoin(res.coin || 0);
			} else {
				window.history.back();
				return false;
			}
			if (loading) {
				setShowLoading(false);
			}
		})
	}



	const disabledDate = (current: any) => {
		if (!current) {
			return false;
		}
		// if (moment(current).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
		// 	return true;
		// }
		// if (current.weekday() == 5 || current.weekday() == 6) {
		// 	return true;
		// }

		return checkActiveDay(current);
	};

	const checkActiveDay = (current: any) => {
		var days = activeDaysCalendarStudent();
		var filter = days.filter((e) => e == moment(current).format('YYYY-MM-DD'));
		if (filter && filter.length > 0) {
			if (getUnixDate(new Date()) <= getUnixDate(filter[0] + 'T00:00:00')) {
				return false;
			}
		}

		if (moment(current).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
			return false;
		}
		return true;
	};

	const dateCellRender = (value: any) => {
		const checkData = calendar.filter(
			(e: any) => moment.unix(e.data).format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD')
		);
		if (checkData && checkData.length > 0) {
			return (
				<div style={{ position: 'absolute', top: -3, left: -3, width: 10, height: 10, zIndex: 9 }}>
					<div style={{ width: 10, height: 10, borderRadius: '50%', background: '#eab300' }} />
				</div>
			);
		}

		return <React.Fragment />;
	};

	const onSelect = (value: any) => {
		const checkData = calendar.filter(
			(e: any) => moment.unix(e.data).format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD')
		);
		setSelectedDate(value);
		setItems(checkData);
	};

	const checkAddButton = () => {
		if (moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
			return true;
		}

		if (selectedDate.weekday() == 5 || selectedDate.weekday() == 6) {
			return true;
		}

		return false;
	};

	const removeDayCalendar = (id: number) => {
		setShowLoading(true);
		requestWebService(REMOVE_ORARIO_GUIDA_CALENDARIO, { id: id }).then((res) => {
			var msg = 'Errore : impossibile eliminare i dati.';
			if (res && res && res.status == 200) {
				var findIndex = items.findIndex((e: any) => e.id == id);
				var findIndexItems = items.findIndex((e: any) => e.id == id);
				items.splice(findIndex, 1);
				items.splice(findIndexItems, 1);
				setItems(items);
				msg = 'Guida eliminata con succes';
			}
			setShowLoading(false);
			present({
				buttons: [],
				message: msg,
				position: 'bottom',
				duration: 4500
			});
		});
	};

	return (
		<IonPage id="content-menu">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/calendario" />
					</IonButtons>
					{/* <IonIcon onClick={() => {
									window.history.back();
								}} slot="end" ios={chevronBackOutline} md={arrowBackOutline} /> */}
					<IonTitle>Calendario</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent >
				<div className="site-calendar-demo-card">
					<Calendar
						headerRender={({ value, type, onChange, onTypeChange }) => {
							const start = 0;
							const end = 12;
							const monthOptions = [];

							const current = value.clone();
							const localeData = value.localeData();
							const months = [];
							for (let i = 0; i < 12; i++) {
								current.month(i);
								months.push(localeData.monthsShort(current));
							}

							for (let index = start; index < end; index++) {
								monthOptions.push(
									<Select.Option className="month-item" value={months[index]} key={`${index}`}>
										{months[index]}
									</Select.Option>
								);
							}
							const month = value.month();

							const year = value.year();
							const year_ = parseInt(moment().format("YYYY"));
							const options = [];
							for (let i = year_; i < year_ + 2; i += 1) {
								options.push(
									<Select.Option key={i} value={i} className="year-item">
										{i}
									</Select.Option>
								);
							}
				
							const mesi = [
								{ titolo: 'gen', value: 1 },
								{ titolo: 'feb', value: 2 },
								{ titolo: 'mar', value: 3 },
								{ titolo: 'apr', value: 4 },
								{ titolo: 'mag', value: 5 },
								{ titolo: 'giu', value: 6 },
								{ titolo: 'lug', value: 7 },
								{ titolo: 'ago', value: 8 },
								{ titolo: 'set', value: 9 },
								{ titolo: 'ott', value: 10 },
								{ titolo: 'nov', value: 11 },
								{ titolo: 'dic', value: 12 }
							];
							return (
								<div style={{ padding: 8 }}>
									<Row gutter={8}>
										<Col>
											<Select
												size="small"
												dropdownMatchSelectWidth={false}
												className="my-year-select"
												onChange={(newYear) => {
													const now = value.clone().year(parseInt(newYear));
													onChange(now);
												}}
												value={String(year)}
											>
												{options}
											</Select>
										</Col>
										<Col>
											<Select
												size="small"
												dropdownMatchSelectWidth={false}
												value={moment(value).format('MMM')}
												onChange={(selectedMonth) => {
													var filter = mesi.filter((e: any) => e.titolo == selectedMonth);
													const newValue = value.clone();
													newValue.month(parseInt((filter[0].value - 1).toString(), 10));
													onChange(newValue);
												}}
											>
												{monthOptions}
											</Select>
										</Col>
									</Row>
								</div>
							);
						}}
						dateCellRender={dateCellRender}
						locale={locale}
						mode={'month'}
						disabledDate={disabledDate}
						fullscreen={false}
						onSelect={onSelect}
						style={{ width: window.innerWidth }}
					/>
				</div>
				<div style={{ padding: '15px 0px' }}>
					<div style={{ width: '100%', margin: '10px 0px', textAlign: 'center' }}>
						{coin > 0 && (btnGuida > 0) && <IonButton
							disabled={checkAddButton()}
							onClick={() => {

								setShowModal({
									isOpen: true,
									day: moment(selectedDate).format("YYYY-MM-DD"),
									id: 0
								});
							}}
							color="primary"
						>
							Aggiungi nuova guida
						</IonButton>}
					</div>

					{items && items.map((item: any, index: number) => {
						var _ora_inizio = moment.unix(item.data_inizio);
						var _current_date = moment();
						var hh = _ora_inizio.diff(_current_date, 'hour');
						var iconEdit = false;
						if (hh > 24) {
							iconEdit = true;
						}
						return (
							<IonItem key={index}>
								<IonLabel className="ion-text-wrap">
									<IonText color="dark">
										<h3>
											{moment.unix(item.data_inizio).format('HH:mm')} -{' '}
											{moment.unix(item.data_fine).format('HH:mm')}{' '}
										</h3>
									</IonText>
									<p>Orario guida</p>
								</IonLabel>
								{iconEdit && <IonIcon icon={createOutline} slot="end" onClick={() => {
									
									setShowModal({
										isOpen: true,
										day: moment.unix(item.data).format("YYYY-MM-DD"),
										id: item.id
									});
								}} />}
								{iconEdit && (
									<IonIcon
										icon={trashOutline}
										color="danger"
										slot="end"
										onClick={() => {
											setShowConfirmModal({
												show: true,
												id: item.id
											});
										}}
									/>
								)}
							</IonItem>
						);
					})}
				</div>
			</IonContent>

			<IonModal isOpen={showModal.isOpen} cssClass="my-custom-class">
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton
								onClick={() => {
									setShowModal({
										isOpen: false,
										day: '',
										id: 0
									});
								}}
							>
								Chiudi
								</IonButton>
						</IonButtons>
						<IonTitle>Seleziona orario</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent style={{ padding: 10 }}>
					<ModalOrari isOpen={showModal.isOpen} istruttore={instructor} day={showModal.day} iduser={infoUser.user} idIscrizione={iscrizione} id={showModal.id} onChange={() => {
						setShowModal({
							isOpen: false,
							day: '',
							id: 0
						});
						setShowLoading(true);
						setTimeout(() => {
							getInfoStudentCalendario('loading');
						}, 2 * 500)
					}} />
				</IonContent>
			</IonModal>

			<IonAlert
				isOpen={showConfirmModal.show}
				backdropDismiss={false}
				cssClass="my-custom-class"
				header={'Esci'}
				message={'Sei sicuro di voler uscire?'}
				buttons={[
					{
						text: 'No',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {
							setShowConfirmModal({
								show: false,
								id: 0
							});
						}
					},
					{
						text: 'SI',
						handler: () => {
							removeDayCalendar(showConfirmModal.id);
							setShowConfirmModal({
								show: false,
								id: 0
							});
						}
					}
				]}
			/>

			<IonLoading
				cssClass="my-custom-class"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={''}
			/>
		</IonPage>
	);
};

export default CalendarioCorsoPage;
