export const IconCalendario = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <rect x="4" y="5" width="16" height="16" rx="2" />
  <line x1="16" y1="3" x2="16" y2="7" />
  <line x1="8" y1="3" x2="8" y2="7" />
  <line x1="4" y1="11" x2="20" y2="11" />
  <rect x="8" y="15" width="2" height="2" />
</svg>);

export const IconPagamenti = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M17.2 7a6 7 0 1 0 0 10" />
  <path d="M13 10h-8m0 4h8" />
</svg>);

export const IconMaterialeGuida = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <circle cx="7" cy="17" r="2" />
  <circle cx="17" cy="17" r="2" />
  <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
</svg>);

export const IconMaterialeTeoria = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
  <line x1="13" y1="8" x2="15" y2="8" />
  <line x1="13" y1="12" x2="15" y2="12" />
</svg>);


export const IconLink = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
  <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
</svg>);

export const IconAula = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <circle cx="9" cy="5" r="1" />
  <circle cx="9" cy="12" r="1" />
  <circle cx="9" cy="19" r="1" />
  <circle cx="15" cy="5" r="1" />
  <circle cx="15" cy="12" r="1" />
  <circle cx="15" cy="19" r="1" />
</svg>);


export const IconScanQrcode = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-qrcode" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <rect x="4" y="4" width="6" height="6" rx="1" />
  <line x1="7" y1="17" x2="7" y2="17.01" />
  <rect x="14" y="4" width="6" height="6" rx="1" />
  <line x1="7" y1="7" x2="7" y2="7.01" />
  <rect x="4" y="14" width="6" height="6" rx="1" />
  <line x1="17" y1="7" x2="17" y2="7.01" />
  <line x1="14" y1="14" x2="17" y2="14" />
  <line x1="20" y1="14" x2="20" y2="14.01" />
  <line x1="14" y1="14" x2="14" y2="17" />
  <line x1="14" y1="20" x2="17" y2="20" />
  <line x1="17" y1="17" x2="20" y2="17" />
  <line x1="20" y1="17" x2="20" y2="20" />
</svg>);

export const IconUsers = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <circle cx="9" cy="7" r="4" />
  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
</svg>);

export const IconOrari = ({ width, height }) => (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock" width={width} height={height} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
<circle cx="12" cy="12" r="9" />
<polyline points="12 7 12 12 15 15" />
</svg>);


