import React, { } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuToggle,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import './Bacheca.css';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { requestWebService, INFO_USER_AUTH } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';
import ExploreContainer from '../components/ExploreContainer';
import { AuthContext } from '../context/AuthContext';
import { IconCalendario, IconPagamenti, IconMaterialeGuida, IconMaterialeTeoria, IconLink, IconAula } from '../utils/icons';


const BachecaApp: React.FC = () => {
	const { setPostoAula, infoUser } = React.useContext(AuthContext);
	const location = useLocation();

	const { status, data, error, refetch } = useQuery<any, any, any>(
		'INFO_USER_AUTH',
		async () => {
			var res = await requestWebService(INFO_USER_AUTH, {});
			if (res && res.status == 200) {
				setPostoAula(res.posto_aula);
				return res;
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);


	React.useEffect(() => {
		if (status === 'success' && location.pathname == '/bacheca') {
			refetch();
		}
		return () => { }
	}, [location])


	const renderPage = () => {
		if (status === 'loading') {
			return <SpinnerLoading />;
		}
		if (status === 'error') {
			return <ExploreContainer msg={"Error Message : " + error.message} />;
		}

		if (status === 'success') {
			return (
				<React.Fragment>
					{data.posto_aula && <IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/calendario-corso-teorico',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconAula width={50} height={50} />
								</div>

								<h3>Prenota posto in aula </h3>
							</Link>
						</div>
					</IonCol>}
					{data.active_corso_pratico && <IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/capitoli-materiale-app/guida',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconMaterialeGuida width={50} height={50} />
								</div>

								<h3>Materiale Guida</h3>
							</Link>
						</div>
					</IonCol>}
					{data.active_corso_teorico && <IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/capitoli-materiale-app/teoria',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconMaterialeTeoria width={50} height={50} />
								</div>
								<h3>Materiale Teoria</h3>
							</Link>
						</div>
					</IonCol>}
					{data.links.map((itm: any, index: number) => {
						return <IonCol key={index} size="6">
							<div className="container-item">
								<a href={itm.link} target="_blank">
									<div className="content-icon-item">
										<IconLink width={50} height={50} />
									</div>
									<h3>{itm.titolo}</h3>
								</a>
							</div>
						</IonCol>
					})}
				</React.Fragment>
			);
		}
		return <React.Fragment />;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuToggle>
							<IonButton>
								<IonIcon icon={menu} color="icon-menu" />
							</IonButton>
						</IonMenuToggle>
					</IonButtons>
					<IonTitle>Autoscuola Crocetta</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>

				<IonRow>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/calendario',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconCalendario width={50} height={50} />
								</div>

								<h3>Calendario guide</h3>
							</Link>
						</div>
					</IonCol>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/corsi',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconPagamenti width={50} height={50} />
								</div>
								<h3>Pagamenti</h3>
							</Link>
						</div>
					</IonCol>
					{renderPage()}
				</IonRow>
			</IonContent>

		</IonPage>
	);
};

export default BachecaApp;
