import { IonButton, IonRouterLink } from '@ionic/react';
import { Link } from 'react-router-dom';
import './ExploreContainer.css';

interface ContainerProps {
  msg: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ msg }) => {
  return (
    <div className="container-error">
      <p>{msg}</p>
      <p>   <IonButton style={{ marginTop: 50 }} color="primary" onClick={() => {
        window.location.reload();
      }}>Ricarica la pagina</IonButton>
      </p>
    </div>
  );
};

export default ExploreContainer;
