import moment from 'moment';
export function isCalcNumber(n) {
  return Number(n.toString().replace(',', '.'));
}


export const langDatePicker = {
  lang: {
    "locale": "it_IT",
    "placeholder": "Seleziona data",
    "rangePlaceholder": ["Data inizio", "Data fine"],
    "today": "Oggi",
    "now": "Adesso",
    "backToToday": "Domani",
    "ok": "Ok",
    "clear": "Rimuovi",
    "month": "Mese",
    "year": "Anno",
    "timeSelect": "Seleziona ora",
    "dateSelect": "Seleziona data",
    "monthSelect": "Seleziona mese",
    "yearSelect": "Seleziona anno",
    "decadeSelect": "Scegli un decennio",
    "previousMonth": "Mese precedente",
    "nextMonth": "Prossimo mese",
    "previousYear": "Ultimo anno",
    "nextYear": "Prossimo anno",
    "previousDecade": "Ultimo decennio",
    "nextDecade": "Prossimo decennio",
    "previousCentury": "L'ultimo secolo",
    "nextCentury": "Prossimo secolo",
    "yearFormat": "YYYY",
    "dateFormat": "M/D/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
  }
};


export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function compare(a, b) {
  if (a.inizio < b.inizio) {
    return -1;
  }
  if (a.inizio > b.inizio) {
    return 1;
  }
  return 0;
}

export function activeDaysCalendarStudent() {
  var currentDate = moment().add(1, 'day');
  var days = [];
  for (var i = 0; i <= 8; i++) {
    days.push(moment(currentDate).add(i, 'days').format("YYYY-MM-DD"));
  }
  return days;
}

export const extractUnixDate = (value) => {
  var format_date = moment.unix(value).format('YYYY-MM-DD')
  var date = moment(format_date + " 00:00:00").unix();
  return date;
}

export const getUnixDate = (value) => {
  var format_date = moment(value).format('YYYY-MM-DD');
  var date = moment(format_date + " 00:00:00").unix();
  return date;
}


export const sortCalendar = (a, b) => {
  if (a.data < b.data) {
    return -1;
  }
  if (a.data > b.data) {
    return 1;
  }
  return 0;
}

