import React, { } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonItem,
	IonLabel,
	IonList,
	IonBackButton
} from '@ionic/react';
import { link as linkIcon, menu, pin } from 'ionicons/icons';
import './MaterialeApp.css';
import { useQuery } from 'react-query';
import { requestWebService, CAPITOLI_MATERIALI_APP } from '../utils/webservice';
import { useHistory, useParams } from 'react-router-dom';
import SpinnerLoading from '../components/SpinnerLoading';
import ExploreContainer from '../components/ExploreContainer';


const CapitoliMaterialeApp: React.FC = () => {
	let { tipo } = useParams<any>();
	const history = useHistory();


	const { status, data, error } = useQuery<any, any, any>(
		'CAPITOLI_MATERIALI_APP',
		async () => {
			if (!['guida', 'teoria'].includes(tipo)) {
				throw new Error("Error");
			}

			var res = await requestWebService(CAPITOLI_MATERIALI_APP, { tipo: tipo });
			if (res && res.status == 200) {

				return res.capitoli || [];
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);

	const renderPage = () => {

		if (status === 'loading') {
			return <SpinnerLoading />;
		}
		if (status === 'error') {
			return <ExploreContainer msg={"Error Message : " + error.message} />;
		}

		if (status === 'success') {
			return (
				<React.Fragment>
					{data.map((item: any, index: number) => {
						return <IonList key={index}>
							<IonItem button onClick={() => {
								history.push({
									pathname: '/materiale-app/' + tipo + '/' + item.id.toString(),
									state: {}
								})
							}} detail>
								<IonLabel>
									{item.titolo}
								</IonLabel>
							</IonItem>
						</IonList>
					})}
				</React.Fragment>
			);
		}

		return <React.Fragment />;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButtons slot="start">
							<IonBackButton text="" defaultHref="/bacheca" />
						</IonButtons>
					</IonButtons>
					<IonTitle>{tipo == 'guida' ? 'Materiali Guida' : 'Materiale Teoria'}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>{renderPage()}</IonContent>
		</IonPage>
	);
};

export default CapitoliMaterialeApp;
