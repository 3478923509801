import React, { useContext, useState } from 'react';
import {
	IonItem,
	IonLabel,
	IonContent,
	IonInput,
	IonPage,
	IonActionSheet,
	IonButton,
	IonLoading,
	useIonToast
} from '@ionic/react';
import './Login.css';
import { requestWebService, LOGIN_AUTH, setToken } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';

const LoginPage: React.FC = () => {
	const { signIn } = useContext(AuthContext);
	const [ showLoading, setShowLoading ] = useState(false);
	const [ showActionSheet, setShowActionSheet ] = useState(false);
	const [ present ] = useIonToast();

	const [ state, setState ] = useState({
		email: '',
		password: '',
		autoscuola: ''
	});

	const login = async () => {
		if (!state.email) {
			present({
				buttons: [],
				message: 'Email campo obbligatorio',
				position: 'top',
				duration: 2500
			});
			return false;
		}
		if (!state.password) {
			present({
				buttons: [],
				message: 'Password campo obbligatorio',
				position: 'top',
				duration: 2500
			});
			return false;
		}
		if (!state.autoscuola) {
			present({
				buttons: [],
				message: 'Autoscuola campo obbligatorio',
				position: 'top',
				duration: 2500
			});
			return false;
		}
		setShowLoading(true);
		var res = await requestWebService(LOGIN_AUTH, state);
		if (res && res.status == 200 && res.token) {
			localStorage.setItem(
				'infouser',
				JSON.stringify({
					autoscuola: res.user.autoscuola,
					user: res.user.user,
					fullname: res.user.fullname,
					userType: res.user.type
				})
			);
			setToken(res.token);
			signIn({
				autoscuola: res.user.autoscuola,
				user: res.user.user,
				fullname: res.user.fullname,
				token: res.token,
				userType: res.user.type
			});
		} else if (res && res.status == 201) {
			present({
				buttons: [],
				message: 'Errore: il tuo account è stato bloccato.',
				position: 'top',
				duration: 2500
			});
		} else if (res && res.status == 1000) {
			present({
				buttons: [],
				message: 'Errore: impossibile eseguire il login.',
				position: 'top',
				duration: 2500
			});
		} else {
			present({
				buttons: [],
				message: 'Errore: impossibile eseguire il login.',
				position: 'top',
				duration: 2500
			});
		}
		setShowLoading(false);
	};

	return (
		<IonPage id="content-menu">
			<IonContent>
				<div className="login-page">
					<div className="form-container-login text-center">
						<div className="logo">
							<img alt="Logo AutoScuola Crocetta" src={'/assets/logo.jpg'} />
						</div>
						<div className="login-form">
							<IonItem>
								<IonLabel position="floating">Email</IonLabel>
								<IonInput
									type="email"
									placeholder={'Email'}
									onIonChange={(e: any) => {
										setState({
											...state,
											email: e.detail.value
										});
									}}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Password</IonLabel>
								<IonInput
									type="password"
									clearOnEdit={true}
									clearInput={true}
									placeholder={'Password'}
									onIonChange={(e: any) => {
										setState({
											...state,
											password: e.detail.value
										});
									}}
								/>
							</IonItem>
							<IonItem
								button
								style={{ marginTop: 15, paddingLeft: 0 }}
								onClick={() => {
									setShowActionSheet(true);
								}}
							>
								{!state.autoscuola && <IonLabel>Seleziona autoscuola</IonLabel>}
								{state.autoscuola == '1' && <IonLabel>Autoscuola Crocetta Parma</IonLabel>}
								{state.autoscuola == '3' && <IonLabel>Autoscuola Crocetta Langhirano</IonLabel>}
							</IonItem>

							<IonButton
								color="crocetta"
								fill="outline"
								onClick={() => {
									login();
								}}
								style={{ minWidth: 120, marginTop: 20 }}
							>
								Entra
							</IonButton>
						</div>
					</div>
				</div>
			</IonContent>
			<IonActionSheet
				isOpen={showActionSheet}
				onDidDismiss={() => setShowActionSheet(false)}
				cssClass="my-custom-class"
				buttons={[
					{
						text: 'Autoscuola Crocetta Parma',
						handler: () => {
							setShowActionSheet(false);
							setState({
								...state,
								autoscuola: '1'
							});
						}
					},
					{
						text: 'Autoscuola Crocetta Langhirano',
						handler: () => {
							setShowActionSheet(false);
							setState({
								...state,
								autoscuola: '3'
							});
						}
					},
					{
						text: 'Chiudi',
						role: 'cancel',
						handler: () => {}
					}
				]}
			/>
			<IonLoading
				cssClass="my-custom-class"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={''}
			/>
			
		</IonPage>
	);
};

export default LoginPage;
