
import { IonSpinner } from '@ionic/react';
import './SpinnerLoading.css';



const SpinnerLoading: React.FC = () => {
    return (
        <div className="container" >
            <IonSpinner />
        </div>
    );
};

export default SpinnerLoading;
