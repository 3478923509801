import React, { } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuToggle,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import './BachecaInstructor.css';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { requestWebService, INFO_USER_AUTH } from '../utils/webservice';
import SpinnerLoading from '../components/SpinnerLoading';
import ExploreContainer from '../components/ExploreContainer';
import { AuthContext } from '../context/AuthContext';
import { IconCalendario, IconPagamenti, IconMaterialeGuida, IconMaterialeTeoria, IconLink, IconAula, IconScanQrcode, IconUsers, IconOrari } from '../utils/icons';


const BachecaInstructorApp: React.FC = () => {
	const { setPostoAula, infoUser } = React.useContext(AuthContext);
	const location = useLocation();


	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuToggle>
							<IonButton>
								<IonIcon icon={menu} color="icon-menu" />
							</IonButton>
						</IonMenuToggle>
					</IonButtons>
					<IonTitle>Autoscuola Crocetta</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>

				<IonRow>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/calendario-instructor',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconCalendario width={50} height={50} />
								</div>

								<h3>Calendario</h3>
							</Link>
						</div>
					</IonCol>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/qrcode-instructor',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconScanQrcode width={50} height={50} />
								</div>
								<h3>Scan QrCode - Guida</h3>
							</Link>
						</div>
					</IonCol>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/list-allievi-instructor',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconUsers width={50} height={50} />
								</div>
								<h3>Allievi</h3>
							</Link>
						</div>
					</IonCol>
					<IonCol size="6">
						<div className="container-item">
							<Link
								to={{
									pathname: '/orari-guida-instructor',
									state: {}
								}}
							>
								<div className="content-icon-item">
									<IconOrari width={50} height={50} />
								</div>
								<h3>Orari guida</h3>
							</Link>
						</div>
					</IonCol>
				</IonRow>
			</IonContent>

		</IonPage>
	);
};

export default BachecaInstructorApp;
