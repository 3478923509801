import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import './Loading.css';

const LoadingPage: React.FC = () => {
	return (
		<IonPage id="content-menu">
			<IonContent fullscreen>
				<div className="content">
					<img
						alt="Logo AutoScuola Crocetta"
						src={'/assets/logo-removebg-preview.png'}
						style={{ width: 300, height: 'auto' }}
					/>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default LoadingPage;
