import { IonLabel, IonItem, IonDatetime, IonButton, useIonAlert, IonLoading, useIonToast } from '@ionic/react';
import React from 'react';
import './ModalAddOrariInstructor.css';
import moment from 'moment';
import { requestWebService, GET_ADD_ORARI_INSTRUCTOR } from '../utils/webservice';

type ParamsFunction = {
  closeModal: Function
}

const ModalAddOrariInstructor: React.FC<ParamsFunction> = ({ closeModal }) => {

  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [showLoading, setShowLoading] = React.useState(false);

  const [state, setState] = React.useState({
    start: moment().format("YYYY-MM-DD"),
    end: '',
    ora_inizio: '',
    ora_fine: '',
  });

  const saveOrari = async () => {
    if (!state.start) {
      alertError("Erore: data inizio campo obbligatorio.");
      return false;
    }
    if (!state.end) {
      alertError("Erore: data fine campo obbligatorio.");
      return false;
    }
    if (!state.ora_inizio) {
      alertError("Erore: ora inizio campo obbligatorio.");
      return false;
    }
    if (!state.ora_fine) {
      alertError("Erore: ora fine campo obbligatorio.");
      return false;
    }

    var params = {
      ora_inizio: state.ora_inizio,
      ora_fine: state.ora_fine,
      start: state.start,
      end: state.end,
      data: state.start
    }

    setShowLoading(true);
    requestWebService(GET_ADD_ORARI_INSTRUCTOR, params).then((res) => {

      if (res && res.status == 200) {
        present({
          buttons: [],
          message: "Dati salvati con successo.",
          position: 'bottom',
          duration: 7500
        });
        closeModal()
      } else {
        present({
          buttons: [],
          message: "Errore: impossibile visualizzare i dati.",
          position: 'bottom',
          duration: 7500
        });
      }
      setShowLoading(false);
    })
  }

  const alertError = (msg: string) => {
    presentAlert({
      cssClass: '',
      header: 'Nuovo orario',
      message: msg,
      buttons: [
        'Chiudi'
      ],
    })
  }

  return (
    <div className="container-modal">
      <IonItem>
        <IonLabel>Data inizio</IonLabel>
        <IonDatetime min={moment().format("YYYY-MM-DD")} max={moment().add(1, 'year').format("YYYY-MM-DD")} displayFormat="DD-MM-YYYY" cancelText="Chiudi" doneText={"Salva"} placeholder="Select Date" value={state.start} onIonChange={(e: any) => {
          setState({
            ...state,
            start: moment(e.detail.value).format("YYYY-MM-DD")
          })
        }}></IonDatetime>
      </IonItem>
      <IonItem>
        <IonLabel>Data fine</IonLabel>
        <IonDatetime min={state.start} max={moment().add(1, 'year').format("YYYY-MM-DD")} displayFormat="DD-MM-YYYY" cancelText="Chiudi" doneText={"Salva"} placeholder="Seleziona data fine" onIonChange={(e: any) => {
          setState({
            ...state,
            end: moment(e.detail.value).format("YYYY-MM-DD")
          })
        }}></IonDatetime>
      </IonItem>
      <IonItem>
        <IonLabel>Ora inizio</IonLabel>
        <IonDatetime displayFormat="HH:mm" minuteValues="0,30" cancelText="Chiudi" doneText={"Salva"} placeholder="Seleziona ora inizio" onIonChange={(e: any) => {
          setState({
            ...state,
            ora_inizio: moment(e.detail.value).format("HH:mm")
          })
        }}></IonDatetime>
      </IonItem>
      <IonItem>
        <IonLabel>Ora fine</IonLabel>
        <IonDatetime min={state.ora_inizio} displayFormat="HH:mm" minuteValues="0,30" cancelText="Chiudi" doneText={"Salva"} placeholder="Seleziona ora fine" onIonChange={(e: any) => {
          setState({
            ...state,
            ora_fine: moment(e.detail.value).format("HH:mm")
          })
        }}></IonDatetime>
      </IonItem>
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <IonButton
          onClick={() => {
            saveOrari();
          }}
        >
          Salva
        </IonButton>
      </div>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={''}
      />
    </div>
  );
};

export default ModalAddOrariInstructor;
