import React, { useContext } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonMenuToggle,
	IonButton,
	IonIcon,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import './DatiInfoStudent.css';
import { useQuery } from 'react-query';
import { requestWebService, INFO_USER } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';

const DatiInfoStudent: React.FC = () => {
	const { infoUser } = useContext(AuthContext);

	const { status, data, error } = useQuery<String, any, any>(
		'INFO_USER',
		async () => {
			var res = await requestWebService(INFO_USER, { id: infoUser.user });
			if (res && res.status == 200) {
				return res.user;
			}
			throw new Error(res.status + ' ' + res.data);
		},
		{
			retry: 1
		}
	);

	const renderPage = () => {
		if (status === 'loading') {
			return <p>Loading</p>;
		}
		if (status === 'error') {
			return <p> Error Message : {error.message}</p>;
		}

		if (status === 'success') {
			return (
				<React.Fragment>
					{data.contract && <IonItem>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3><a href={"https://portaleautoscuolacrocetta.it" + data.contract} target="_blank">Scarica contratto</a></h3>
							</IonText>
							<p>Contratto</p>
						</IonLabel>
					</IonItem>}
					<IonItem>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{data.nome}</h3>
							</IonText>
							<p>Nome</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{data.cognome}</h3>
							</IonText>
							<p>Cognome</p>
						</IonLabel>
					</IonItem>
					{data.data_nascita && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{moment(data.data_nascita).format("DD MMM YYYY")}</h3>
								</IonText>
								<p>Data di nascita</p>
							</IonLabel>
						</IonItem>
					)}
					{data.luogo_nascita && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.luogo_nascita}</h3>
								</IonText>
								<p>Luogo di nascita</p>
							</IonLabel>
						</IonItem>
					)}
					{data.codice_fiscale && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.codice_fiscale}</h3>
								</IonText>
								<p>Codice fiscale</p>
							</IonLabel>
						</IonItem>
					)}
					<IonItem>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{data.email}</h3>
							</IonText>
							<p>Email</p>
						</IonLabel>
					</IonItem>
					{data.cellulare && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.cellulare}</h3>
								</IonText>
								<p>Cellulare</p>
							</IonLabel>
						</IonItem>
					)}
					{data.telefono && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.telefono}</h3>
								</IonText>
								<p>Telefono</p>
							</IonLabel>
						</IonItem>
					)}
					{data.provincia && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.provincia}</h3>
								</IonText>
								<p>Provincia</p>
							</IonLabel>
						</IonItem>
					)}
					{data.localita && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.localita}</h3>
								</IonText>
								<p>Località</p>
							</IonLabel>
						</IonItem>
					)}
					{data.indirizzo && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.indirizzo}</h3>
								</IonText>
								<p>Indirizzo</p>
							</IonLabel>
						</IonItem>
					)}
					{data.note_allievo && (
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<IonText color="dark">
									<h3>{data.note_allievo}</h3>
								</IonText>
								<p>Note</p>
							</IonLabel>
						</IonItem>
					)}
				</React.Fragment>
			);
		}

		return <React.Fragment />;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuToggle>
							<IonButton>
								<IonIcon icon={menu} color="icon-menu" />
							</IonButton>
						</IonMenuToggle>
					</IonButtons>
					<IonTitle>I miei dati</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>{renderPage()}</IonContent>
		</IonPage>
	);
};

export default DatiInfoStudent;
