import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
	IonApp,
	IonMenu,
	IonHeader,
	IonRouterOutlet,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonAlert,
	IonMenuToggle
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Calendario from './pages/Calendario';
import Corsi from './pages/Corsi';
import DatiInfoStudent from './pages/DatiInfoStudent';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../node_modules/antd/dist/antd.css';
import './theme/variables.css';
import { useCookies } from 'react-cookie';
import LoginPage from './pages/Login';
import { AuthContext } from './context/AuthContext';
import { requestWebService, setToken, VALIDATION_TOKEN } from './utils/webservice';
import LoadingPage from './pages/Loading';
import { QueryClient, QueryClientProvider } from 'react-query';
import PagamentoCorsoPage from './pages/PagamentoCorso';
import CalendarioCorsoPage from './pages/CalendarioCorso';
import ListGuidePage from './pages/ListGuide';
import BachecaApp from './pages/Bacheca';
import MaterialeApp from './pages/MaterialeApp';
import CalendarioCorsoTeoricoPage from './pages/CalendarioCorsoTeorico';
import CapitoliMaterialeApp from './pages/CapitoliMaterialeApp';
import BachecaInstructorApp from './pages/BachecaInstructor';
import ListAllieviInstructor from './pages/ListAllieviInstructor';
import QrcodeInstructor from './pages/QrcodeInstructor';
import CalendarioInstructorPage from './pages/CalendarioInstructor';
import OrariGuidaInstructorPage from './pages/OrariGuidaInstructor';
const dev = process.env.NODE_ENV !== 'production';


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			staleTime: 0
		}
	}
});

const App: React.FC = () => {
	const [cookies, setCookie, removeCookie] = useCookies([]);
	const tkn = cookies['__tknAC__'];
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);

	const [state, dispatch] = React.useReducer(
		(prevState: any, action: any) => {
			switch (action.type) {
				case 'SIGN_IN':
					return {
						...prevState,
						loading: false,
						app_started: action.app_started,
						user: action.user || null,
						fullname: action.fullname || null,
						token: action.token || null,
						userType: action.userType || 'student',
						autoscuola: action.autoscuola || null
					};
				case 'SET_LOADING':
					return {
						...prevState,
						loading: false
					};
				case 'POSTO_AULA':
					return {
						...prevState,
						posto_aula: action.posto_aula || false
					};
			}
		},
		{
			app_started: tkn ? true : false,
			loading: tkn ? false : true,
			user: null,
			fullname: null,
			token: null,
			userType: null,
			autoscuola: null,
			posto_aula: false
		}
	);

	React.useEffect(() => {
		const bootstrapAsync = async () => {
			let deferredPrompt;
			window.addEventListener('beforeinstallprompt', (e: any) => {

				console.log('beforeinstallprompt Event fired');
				// Prevent Chrome 67 and earlier from automatically showing the prompt
				e.preventDefault();
				// Stash the event so it can be triggered later.
				deferredPrompt = e;
			});


			let params = {
				app_started: false,
				user: null,
				fullname: null,
				token: null,
				userType: null,
				autoscuola: null
			};
			if (tkn && localStorage.getItem('infouser')) {
				setToken(tkn);
				var infouser = JSON.parse(localStorage.getItem('infouser') || '{}');
				params = {
					app_started: true,
					user: infouser.user,
					fullname: infouser.fullname,
					token: tkn,
					userType: infouser.userType || 'student',
					autoscuola: infouser.autoscuola
				};
				// var res: any = await requestWebService(VALIDATION_TOKEN, {});
				// if (res && res.status == 200) {

				// } else if (res && res.status == 201) {
				// 	removeCookie('__tknAC__');
				// }
			}
			dispatch({ type: 'SIGN_IN', ...params });
		};
		bootstrapAsync();
	}, []);

	const authContext = {
		//		secure: dev ? false : true,
		signIn: async (data: any) => {
			setCookie('__tknAC__', data.token, {
				secure: false,
				path: '/',
				maxAge: 30 * 24 * 60 * 60 * 1000
			});
			dispatch({ type: 'SIGN_IN', app_started: true, ...data });
		},
		infoUser: state,
		setPostoAula: async (posto_aula: boolean) => {
			dispatch({ type: 'POSTO_AULA', posto_aula: posto_aula });
		},
	};

	if (!state.app_started && state.loading) {
		return (
			<IonApp>
				<LoadingPage />
			</IonApp>
		);
	}

	if (!state.app_started) {
		return (
			<AuthContext.Provider value={authContext}>
				<IonApp>
					<LoginPage />
				</IonApp>
			</AuthContext.Provider>
		);
	}

	return (
		<AuthContext.Provider value={authContext}>
			<QueryClientProvider client={queryClient}>
				<IonApp>
					<IonReactRouter>
						<IonMenu contentId="content-menu">
							<IonHeader>
								<IonToolbar color="crocetta">
									<IonTitle color="light">{state.fullname}</IonTitle>
								</IonToolbar>
							</IonHeader>
							<IonContent fullscreen>
								<div className="content-menu">
									<div className="items-menu">
										{state.autoscuola == '1' && (
											<p className="name-autoscuola">Autoscuola Crocetta Parma</p>
										)}
										{state.autoscuola == '3' && (
											<p className="name-autoscuola">Autoscuola Crocetta Langhirano</p>
										)}
										{state.userType == 'student' && <IonList>
											<IonMenuToggle><IonItem routerLink="/bacheca">Home</IonItem></IonMenuToggle>
											<IonMenuToggle><IonItem routerLink="/calendario">Calendario guide</IonItem></IonMenuToggle>
											<IonMenuToggle><IonItem routerLink="/corsi">Pagamenti</IonItem></IonMenuToggle>
											<IonMenuToggle><IonItem routerLink="/dati-info-student">I miei dati</IonItem></IonMenuToggle>
											<IonMenuToggle>
												<IonItem
													onClick={async () => {
														setShowConfirmModal(true);
													}}
												>
													Esci
												</IonItem>
											</IonMenuToggle>
										</IonList>}
										{state.userType == 'instructor' && <IonList>
											<IonMenuToggle><IonItem routerLink="/bacheca-instructor">Home</IonItem></IonMenuToggle>
											<IonMenuToggle>
												<IonItem
													onClick={async () => {
														setShowConfirmModal(true);
													}}
												>
													Esci
												</IonItem>
											</IonMenuToggle>
										</IonList>}
									</div>

									<div className="logo-menu">
										<img
											alt="Logo AutoScuola Crocetta"
											src={'/assets/logo.jpg'}
											style={{ width: 180 }}
										/>
									</div>
								</div>
							</IonContent>
						</IonMenu>


						<IonRouterOutlet id="content-menu">
							<Route exact path="/">
								{!state.userType || state.userType == 'student' ? <Redirect to="/bacheca" /> : <Redirect to="/bacheca-instructor" />}
							</Route>
							<Route exact path="/bacheca">
								<BachecaApp />
							</Route>
							<Route exact path="/bacheca-instructor">
								<BachecaInstructorApp />
							</Route>
							<Route exact path="/calendario">
								<Calendario />
							</Route>
							<Route exact path="/calendario-corso-teorico">
								<CalendarioCorsoTeoricoPage />
							</Route>
							<Route exact path="/capitoli-materiale-app/:tipo">
								<CapitoliMaterialeApp />
							</Route>
							<Route exact path="/materiale-app/:tipo/:capitolo">
								<MaterialeApp />
							</Route>
							<Route exact path="/calendario-corso/:corso/:iscrizione">
								<CalendarioCorsoPage />
							</Route>
							<Route exact path="/list-guide-corso/:corso/:iscrizione">
								<ListGuidePage />
							</Route>
							<Route exact path="/list-allievi-instructor">
								<ListAllieviInstructor />
							</Route>
						
							<Route exact path="/corsi">
								<Corsi />
							</Route>
							<Route exact path="/payments-corso/:corso">
								<PagamentoCorsoPage />
							</Route>
							<Route path="/dati-info-student">
								<DatiInfoStudent />
							</Route>
							<Route path="/qrcode-instructor">
								<QrcodeInstructor />
							</Route>
							<Route path="/calendario-instructor">
								<CalendarioInstructorPage />
							</Route>
							<Route path="/orari-guida-instructor">
								<OrariGuidaInstructorPage />
							</Route>
							<Route render={() => <Redirect to="/" />} />
						</IonRouterOutlet>
						<IonAlert
							isOpen={showConfirmModal}
							backdropDismiss={false}
							cssClass="my-custom-class"
							header={'Esci'}
							message={'Sei sicuro di voler uscire?'}
							buttons={[
								{
									text: 'No',
									role: 'cancel',
									cssClass: 'secondary',
									handler: (blah) => {
										setShowConfirmModal(false);
									}
								},
								{
									text: 'SI',
									handler: () => {
										setShowConfirmModal(false);
										removeCookie('__tknAC__');
										dispatch({ type: 'SIGN_IN' });
										window.location.href = '/';
									}
								}
							]}
						/>
					</IonReactRouter>
				</IonApp>

			</QueryClientProvider>
		</AuthContext.Provider>
	);
};

export default App;
