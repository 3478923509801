import React, { useContext } from 'react';
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonButton,
	IonIcon,
	IonItem,
	useIonToast,
	IonLoading,
	IonText,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonCol,
	IonGrid,
	IonRow,
	IonModal,
	IonAlert
} from '@ionic/react';
import './OrariGuidaInstructor.css';
import { requestWebService, GET_ORARI_INSTRUCTOR, DELETE_ORARIO_GUIDE } from '../utils/webservice';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';
import { capitalize } from '../utils/utils';
import ModalAddOrariInstructor from '../components/ModalAddOrariInstructor';
import { trashOutline } from 'ionicons/icons';
moment().locale("it");

const OrariGuidaInstructorPage: React.FC = () => {
	const { infoUser } = useContext(AuthContext);

	const [present] = useIonToast();
	const [showLoading, setShowLoading] = React.useState(true);
	const [showModal, setShowModal] = React.useState(false);
	const [state, setState] = React.useState({
		items: [],
		filterMonth: capitalize(moment().format('MMMM')),
		filterYear: moment().format('YYYY')
	});
	const [showConfirmModal, setShowConfirmModal] = React.useState({
		show: false,
		id: 0
	});

	React.useEffect(() => {
		getOrariInstructor(moment().format("YYYY-MM-DD"));
	}, []);

	const getOrariInstructor = (date: string) => {

		requestWebService(GET_ORARI_INSTRUCTOR, { date: date }).then((res) => {
			if (res && res.status == 200) {
				setState({
					...state,
					items: res.data || [],
					filterMonth: capitalize(moment(date).format('MMMM')),
					filterYear: moment(date).format('YYYY')
				});
			} else {
				present({
					buttons: [],
					message: "Errore: impossibile visualizzare i dati.",
					position: 'bottom',
					duration: 7500
				});
			}
			setShowLoading(false);
		})
	}

	const removeGuidaCalendar = async (id: number) => {
		setShowLoading(true);
		requestWebService(DELETE_ORARIO_GUIDE, { id: id }).then((res) => {
			if (res && res.status && res.status == 200) {
				present({
					buttons: [],
					message: "Dati salvati con successo.",
					position: 'bottom',
					duration: 7500
				});

				var mm = moment().format("MM");
				if (state.filterMonth) {
					mm = moment().month(state.filterMonth).format("MM");
				}
				var year = state.filterYear || moment().format("YYYY");
				var date = moment(year + "-" + mm + "-01T00:00:00").format("YYYY-MM-DD");
				getOrariInstructor(date);

			} else {
				present({
					buttons: [],
					message: "Errore: impossibile visualizzare i dati.",
					position: 'bottom',
					duration: 7500
				});
			}
			setShowLoading(false);
		})
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/bacheca-instructor" />
					</IonButtons>
					<IonTitle>Orari Guida</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<span style={{ paddingLeft: 10, paddingRight: 10, fontWeight: 'bold' }}>Filtri</span>
				<IonItem>
					<IonLabel>Anno</IonLabel>
					<IonSelect cancelText={"Chiudi"} okText={"Salva"} defaultValue={state.filterYear} placeholder={state.filterYear} onIonChange={e => {
						if (e.detail.value) {
							var mm = moment().month(state.filterMonth).format("MM");
							var date = moment(e.detail.value + "-" + mm + "-01T00:00:00").format("YYYY-MM-DD");
							getOrariInstructor(date);
						}
					}}>
						<IonSelectOption value={'2020'}>{'2020'}</IonSelectOption>
						<IonSelectOption value={'2021'}>{'2021'}</IonSelectOption>
					</IonSelect>
				</IonItem>
				<IonItem>
					<IonLabel>Mese</IonLabel>
					<IonSelect cancelText={"Chiudi"} okText={"Salva"} defaultValue={state.filterMonth} placeholder={state.filterMonth} onIonChange={e => {
						if (e.detail.value) {
							var mm = moment().month(e.detail.value).format("MM");
							var date = moment(state.filterYear + "-" + mm + "-01T00:00:00").format("YYYY-MM-DD");
							getOrariInstructor(date);
						}
					}}>
						{moment.months().map((item, index) => {
							return <IonSelectOption key={index} value={capitalize(item)}>{capitalize(item)}</IonSelectOption>
						})}
					</IonSelect>
				</IonItem>
				<IonGrid>
					<IonRow>
						<IonCol size="6">
							<h1 style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}>Orari</h1>
						</IonCol>
						<IonCol size="6">
							<IonButton onClick={() => {
								setShowModal(true);
							}}>Aggiungi orario</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
				{!showLoading && state.items.length <= 0 && <h1 style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10, textAlign: 'center', color: '#d4d4d4' }}>Nessun orario inserito</h1>}
				{state.items && state.items.map((item: any, index: number) => {
					return <IonItem key={index}>
						<IonLabel className="ion-text-wrap">
							<IonText color="dark">
								<h3>{item.data}</h3>
							</IonText>
							<p>{item.ora_inizio} - {item.ora_fine}</p>
						</IonLabel>
						<IonIcon icon={trashOutline} slot="end" color="danger" onClick={() => {
							setShowConfirmModal({
								show: true,
								id: item.id
							});
						}} />
					</IonItem>
				})}
			</IonContent>
			<IonModal isOpen={showModal} cssClass="my-custom-class">
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton
								onClick={() => {
									setShowModal(false);
								}}
							>
								Chiudi
							</IonButton>
						</IonButtons>
						<IonTitle>Aggiungi nuovo orario</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent style={{ padding: 10 }}>
					<ModalAddOrariInstructor closeModal={() => {
						setShowModal(false);
						var mm = moment().format("MM");
						if (state.filterMonth) {
							mm = moment().month(state.filterMonth).format("MM");
						}
						var year = state.filterYear || moment().format("YYYY");
						var date = moment(year + "-" + mm + "-01T00:00:00").format("YYYY-MM-DD");
						getOrariInstructor(date);

					}} />
				</IonContent>
			</IonModal>
			<IonLoading
				cssClass="my-custom-class"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={''}
			/>
			<IonAlert
				isOpen={showConfirmModal.show}
				backdropDismiss={false}
				cssClass="my-custom-class"
				header={'Elimina'}
				message={'Sei sicuro di voler eliminare l\'orario?'}
				buttons={[
					{
						text: 'No',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {
							setShowConfirmModal({
								show: false,
								id: 0
							});
						}
					},
					{
						text: 'SI',
						handler: () => {
							removeGuidaCalendar(showConfirmModal.id);
							setShowConfirmModal({
								show: false,
								id: 0
							});
						}
					}
				]}
			/>
		</IonPage>
	);
};

export default OrariGuidaInstructorPage;
